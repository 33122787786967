<template>
  <div class="personalized-plan">
    <!-- 个性化方案  个性化推荐 -->
    <div class="plan-title">
      {{ formObj.disciplineTypeName }} {{ formObj.admitBatchName }}
    </div>
    <el-form :model="listQuery" inline label-width="80px">
      <el-form-item label="院校省份">
        <el-select v-model="listQuery.collegeProvince" placeholder="请选择" style="width: 100%"
          @change="changeCollegeProvince" clearable value-key="id">
          <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="院校特性">
        <el-select v-model="listQuery.collegeCharacter" placeholder="请选择" style="width: 200px" multiple collapse-tags
          @change="changeCollegeCharacter" clearable>
          <el-option v-for="item in characteristicList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="院校类型">
        <el-select v-model="listQuery.ambitTypes" placeholder="请选择" style="width: 100%" multiple
          @change="changeAmbitTypes" clearable collapse-tags>
          <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div class="tab-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="可冲击" name="1"></el-tab-pane>
        <el-tab-pane label="较稳妥" name="2"></el-tab-pane>
        <el-tab-pane label="可保底" name="3"></el-tab-pane>
      </el-tabs>
      <div class="btn-box" @click="previewVolunteers">
        预览志愿表（{{ volunteerList.length }}）
      </div>
    </div>

    <!--可冲击  -->
    <el-table v-if="activeName == '1'" :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="录取概率" align="center" width="80">
        <template v-slot="scope">
          <div>
            <div class="red-color">{{ scope.row.admitRate || 0 }}%</div>
            <div class="red-color">可冲击</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="year + '招生计划'" align="center">
        <el-table-column prop="address" align="center" label="招生院校" width="200">
          <template v-slot="scope">
            <div>
              <div v-if="voPa == 3">{{ scope.row.specialtyOptions || "" }}</div>
              <div v-if="voPa != 3">{{ scope.row.collegeName || "" }}</div>
              <div>
                {{ scope.row.collegeCharacter | filterCollegeCharacter }}
                {{ scope.row.disciplineAmbit | filterDisciplineAmbit }}
                {{ scope.row.collegeProvince || "" }}
              </div>
              <div v-if="voPa == 3">{{ scope.row.specialtyNames || "" }}</div>
              <div v-if="voPa != 3">{{ scope.row.collegeCode || "" }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="招生专业" width="150" show-overflow-tooltip>
          <template v-slot="scope">
            <div v-if="voPa != 2" style="text-align: center">
              <div class="view-btn" @click="viewDiscipline(scope.row)">
                查看专业
              </div>
            </div>
            <div v-if="voPa == 2">
              <div>{{ scope.row.specialtyName || "" }}</div>
              <div>
                专业代码{{ scope.row.specialtyName || "" }} 招生人数{{
                  scope.row.planNum || "0"
                }}人
              </div>
              <div v-if="scope.row.requiredType === 0">不提科目要求</div>
              <div v-else-if="scope.row.requiredType === 1">
                科目要求：任选一门报考（{{
                  scope.row.requiredDiscipline || ""
                }}）
              </div>
              <div v-else-if="scope.row.requiredType === 2">
                科目要求：均需报考（{{ scope.row.requiredDiscipline || "" }}）
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column :label="admissionYear + '年'" align="center">
        <el-table-column prop="scoreLowest" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateLowest" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitNum" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column :label="(admissionYear - 1) + '年'" align="center">
        <el-table-column prop="scoreYearLast" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateYearLast" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitYearLast" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column :label="(admissionYear - 2) + '年'" align="center">
        <el-table-column prop="scoreYearOther" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateYearOther" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitYearOther" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template v-slot="scope">
          <div>
            <div class="view-btn" @click="joinVolunteers(scope.row)">
              加入志愿
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 较稳妥 -->
    <el-table v-if="activeName == '2'" :data="tableData2" style="width: 100%">
      <el-table-column prop="date" label="录取概率" align="center" width="80">
        <template v-slot="scope">
          <div>
            <div class="red-color">{{ scope.row.admitRate || 0 }}%</div>
            <div class="yellow-color">较稳妥</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="year + '招生计划'" align="center">
        <el-table-column align="center" label="招生院校" width="200">
          <template v-slot="scope">
            <div>
              <div v-if="voPa == 3">{{ scope.row.specialtyOptions || "" }}</div>
              <div v-if="voPa != 3">{{ scope.row.collegeName || "" }}</div>
              <div>
                {{ scope.row.collegeCharacter | filterCollegeCharacter }}
                {{ scope.row.disciplineAmbit | filterDisciplineAmbit }}
                {{ scope.row.collegeProvince || "" }}
              </div>
              <div v-if="voPa == 3">{{ scope.row.specialtyNames || "" }}</div>
              <div v-if="voPa != 3">{{ scope.row.collegeCode || "" }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="招生专业" width="150" show-overflow-tooltip>
          <template v-slot="scope">
            <div v-if="voPa != 2" style="text-align: center">
              <div class="view-btn" @click="viewDiscipline(scope.row)">
                查看专业
              </div>
            </div>
            <div v-if="voPa == 2">
              <div>{{ scope.row.specialtyName || "" }}</div>
              <div>
                专业代码{{ scope.row.specialtyName || "" }} 招生人数{{
                  scope.row.planNum || "0"
                }}人
              </div>
              <div v-if="scope.row.requiredType === 0">不提科目要求</div>
              <div v-else-if="scope.row.requiredType === 1">
                科目要求：任选一门报考（{{
                  scope.row.requiredDiscipline || ""
                }}）
              </div>
              <div v-else-if="scope.row.requiredType === 2">
                科目要求：均需报考（{{ scope.row.requiredDiscipline || "" }}）
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column :label="admissionYear + '年'" align="center">
        <el-table-column prop="scoreLowest" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateLowest" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitNum" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column :label="(admissionYear - 1) + '年'" align="center">
        <el-table-column prop="scoreYearLast" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateYearLast" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitYearLast" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column :label="(admissionYear - 2) + '年'" align="center">
        <el-table-column prop="scoreYearOther" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateYearOther" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitYearOther" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template v-slot="scope">
          <div>
            <div class="view-btn" @click="joinVolunteers(scope.row)">
              加入志愿
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 可保底 -->
    <el-table v-if="activeName == '3'" :data="tableData3" style="width: 100%">
      <el-table-column prop="date" label="录取概率" align="center" width="80">
        <template v-slot="scope">
          <div>
            <div class="red-color">{{ scope.row.admitRate || 0 }}%</div>
            <div class="green-color">可保底</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="year + '招生计划'" align="center">
        <el-table-column prop="address" align="center" label="招生院校" width="200">
          <template v-slot="scope">
            <div>
              <div v-if="voPa == 3">{{ scope.row.specialtyOptions || "" }}</div>
              <div v-if="voPa != 3">{{ scope.row.collegeName || "" }}</div>
              <div>
                {{ scope.row.collegeCharacter | filterCollegeCharacter }}
                {{ scope.row.disciplineAmbit | filterDisciplineAmbit }}
                {{ scope.row.collegeProvince || "" }}
              </div>
              <div v-if="voPa == 3">{{ scope.row.specialtyNames || "" }}</div>
              <div v-if="voPa != 3">{{ scope.row.collegeCode || "" }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="招生专业" width="150" show-overflow-tooltip>
          <template v-slot="scope">
            <div v-if="voPa != 2" style="text-align: center">
              <div class="view-btn" @click="viewDiscipline(scope.row)">
                查看专业
              </div>
            </div>
            <div v-if="voPa == 2">
              <div>{{ scope.row.specialtyName || "" }}</div>
              <div>
                专业代码{{ scope.row.specialtyName || "" }} 招生人数{{
                  scope.row.planNum || "0"
                }}人
              </div>
              <div v-if="scope.row.requiredType === 0">不提科目要求</div>
              <div v-else-if="scope.row.requiredType === 1">
                科目要求：任选一门报考（{{
                  scope.row.requiredDiscipline || ""
                }}）
              </div>
              <div v-else-if="scope.row.requiredType === 2">
                科目要求：均需报考（{{ scope.row.requiredDiscipline || "" }}）
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column :label="admissionYear + '年'" align="center">
        <el-table-column prop="scoreLowest" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateLowest" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitNum" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column :label="(admissionYear - 1) + '年'" align="center">
        <el-table-column prop="scoreYearLast" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateYearLast" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitYearLast" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column :label="(admissionYear - 2) + '年'" align="center">
        <el-table-column prop="scoreYearOther" align="center" label="最低分" min-width="70"></el-table-column>
        <el-table-column prop="accumulateYearOther" align="center" label="最低位次"></el-table-column>
        <el-table-column prop="admitYearOther" align="center" label="录取人数"></el-table-column>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template v-slot="scope">
          <div>
            <div class="view-btn" @click="joinVolunteers(scope.row)">
              加入志愿
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="我的志愿表" :visible.sync="dialogVisible" width="1400px">
      <div class="volunteer-content">
        <el-table v-if="voPa != 2" :data="volunteerList" style="width: 100%" border class="table-box">
          <el-table-column label="志愿" align="center" width="150">
            <template v-slot="scope">
              <div>
                <div>平行志愿{{ scope.$index + 1 }}</div>
                <div class="red-color" v-if="scope.row.type == 1">
                  录取率{{ scope.row.admitRate || 0 }}%
                </div>
                <div class="yellow-color" v-if="scope.row.type == 2">
                  录取率{{ scope.row.admitRate || 0 }}%
                </div>
                <div class="green-color" v-if="scope.row.type == 3">
                  录取率{{ scope.row.admitRate || 0 }}%
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date1" label="院校名称" align="center" width="150">
            <template v-slot="scope">
              <div>
                <div style="color: #24b699">
                  {{ scope.row.collegeName || "" }}
                </div>
                <div>{{ scope.row.collegeCode || "" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" class-name="more-header">
            <template slot="header" slot-scope="scope">
              <tableItem :indexNumber="1"></tableItem>
            </template>
            <template slot-scope="scope">
              <!-- {{ scope.$index }} -->
              <tableHeader :indexNumber="1" :dataObj="scope.row.specialtyList"></tableHeader>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" class-name="more-header">
            <template slot="header" slot-scope="scope">
              <tableItem :indexNumber="2"></tableItem>
            </template>
            <template slot-scope="scope">
              <!-- {{ scope.$index }} -->
              <tableHeader :indexNumber="2" :dataObj="scope.row.specialtyList"></tableHeader>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" class-name="more-header">
            <template slot="header" slot-scope="scope">
              <tableItem :indexNumber="3"></tableItem>
            </template>
            <template slot-scope="scope">
              <!-- {{ scope.$index }} -->
              <tableHeader :indexNumber="4" :dataObj="scope.row.specialtyList"></tableHeader>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" class-name="more-header">
            <template slot="header" slot-scope="scope">
              <tableItem :indexNumber="4"></tableItem>
            </template>
            <template slot-scope="scope">
              <!-- {{ scope.$index }} -->
              <tableHeader :indexNumber="5" :dataObj="scope.row.specialtyList"></tableHeader>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" class-name="more-header">
            <template slot="header" slot-scope="scope">
              <tableItem :indexNumber="5"></tableItem>
            </template>
            <template slot-scope="scope">
              <!-- {{ scope.$index }} -->
              <tableHeader :indexNumber="5" :dataObj="scope.row.specialtyList"></tableHeader>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" class-name="more-header">
            <template slot="header" slot-scope="scope">
              <tableItem :indexNumber="6"></tableItem>
            </template>
            <template slot-scope="scope">
              <!-- {{ scope.$index }} -->
              <tableHeader :indexNumber="6" :dataObj="scope.row.specialtyList"></tableHeader>
            </template>
          </el-table-column>
          <!-- <div v-for="index of 6" :key="index">
            <el-table-column
              align="center"
              width="240"
              class-name="more-header"
            >
              <template slot="header" slot-scope="scope">
                <tableItem :indexNumber="index"></tableItem>
              </template>
              <template slot-scope="scope">
                <tableHeader
                  :indexNumber="index"
                  :dataObj="scope.row.specialtyList"
                ></tableHeader>
              </template>
            </el-table-column>
          </div> -->
          <el-table-column label="排序" align="center" prop="sort " width="100">
            <template v-slot="scope">
              <el-input type="number" size="small" v-model="scope.row.sort"
                @change="tableRank(scope.row, scope.$index)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template v-slot="scope">
              <div class="delete-btn" @click="deleteItem(scope.$index)">
                删除
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-else :data="volunteerList" style="width: 100%" class="table-box">
          <el-table-column prop="date" label="志愿" align="center" width="150">
            <template v-slot="scope">
              <div>
                <div>平行志愿{{ scope.$index + 1 }}</div>
                <div class="red-color" v-if="scope.row.type == 1">
                  录取率{{ scope.row.admitRate || 0 }}%
                </div>
                <div class="yellow-color" v-if="scope.row.type == 2">
                  录取率{{ scope.row.admitRate || 0 }}%
                </div>
                <div class="green-color" v-if="scope.row.type == 3">
                  录取率{{ scope.row.admitRate || 0 }}%
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="院校名称" align="center" width="150">
            <template v-slot="scope">
              <div>
                <div style="color: #24b699">
                  {{ scope.row.collegeName || "" }}
                </div>
                <div>{{ scope.row.collegeCode || "" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="专业名称" align="center" width="150">
            <template v-slot="scope">
              <div>
                <div style="color: #74d1be">
                  {{ scope.row.specialtyName || "" }}
                </div>
                <div>{{ scope.row.specialtyCode || "" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="yearLast + '年'" align="center" width="240">
            <el-table-column prop="scoreLowest" align="center" label="最低分" min-width="70"></el-table-column>
            <el-table-column prop="accumulateLowest" align="center" label="最低位次"></el-table-column>
            <el-table-column prop="admitNum" align="center" label="录取人数"></el-table-column>
          </el-table-column>
          <el-table-column :label="yearLast1 + '年'" align="center">
            <el-table-column prop="scoreYearLast" align="center" label="最低分" min-width="70"></el-table-column>
            <el-table-column prop="accumulateYearLast" align="center" label="最低位次"></el-table-column>
            <el-table-column prop="admitYearLast" align="center" label="录取人数"></el-table-column>
          </el-table-column>
          <el-table-column :label="yearLast2 + '年'" align="center">
            <el-table-column prop="scoreYearOther" align="center" label="最低分" min-width="70"></el-table-column>
            <el-table-column prop="accumulateYearOther" align="center" label="最低位次"></el-table-column>
            <el-table-column prop="admitYearOther" align="center" label="录取人数"></el-table-column>
          </el-table-column>
          <el-table-column label="排序" align="center" prop="sort " width="100">
            <template v-slot="scope">
              <el-input type="number" size="small" v-model="scope.row.sort"
                @change="tableRank(scope.row, scope.$index)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template v-slot="scope">
              <div class="delete-btn" @click="deleteItem(scope.$index)">
                删除
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="tip-mesage">
          注：志愿表中专业最低分、最低位次、录取人数仅展示上年数据，如需查看更多数据请前往查分数功能查看
        </div>
        <div class="save-btn" @click="savePlan">保存志愿表</div>
      </div>
    </el-dialog>

    <el-dialog :title="titleName" :visible.sync="dialogVisible1" width="1400px">
      <el-table :data="admissionsMajor" style="width: 100%" class="table-box">
        <el-table-column prop="date" label="录取概率" align="center" width="80">
          <template v-slot="scope">
            <div>
              <div class="red-color">{{ scope.row.admitRate || 0 }}%</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="year + '招生计划'" align="center">
          <el-table-column prop="address" align="center" label="招生专业" width="180">
            <template v-slot="scope">
              <div>
                <div>{{ scope.row.specialtyName || "" }}</div>
                <div>
                  专业代码{{ scope.row.specialtyName || "" }} 招生人数{{
                    scope.row.planNum || "0"
                  }}人
                </div>
                <div v-if="scope.row.requiredType === 0">专业无要求</div>
                <div v-else-if="scope.row.requiredType === 1">
                  专业要求：任选一门报考（{{
                    scope.row.requiredDiscipline || ""
                  }}）
                </div>
                <div v-else-if="scope.row.requiredType === 2">
                  专业要求：均需报考（{{ scope.row.requiredDiscipline || "" }}）
                </div>
                <!-- <div v-else>--</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="admissionYear + '年'" align="center">
          <el-table-column prop="scoreLowest" align="center" label="最低分" min-width="70"></el-table-column>
          <el-table-column prop="accumulateLowest" align="center" label="最低位次"></el-table-column>
          <el-table-column prop="admitNum" align="center" label="录取人数"></el-table-column>
        </el-table-column>
        <el-table-column :label="(admissionYear - 1) + '年'" align="center">
          <el-table-column prop="scoreYearLast" align="center" label="最低分" min-width="70"></el-table-column>
          <el-table-column prop="accumulateYearLast" align="center" label="最低位次"></el-table-column>
          <el-table-column prop="admitYearLast" align="center" label="录取人数"></el-table-column>
        </el-table-column>
        <el-table-column :label="(admissionYear - 2) + '年'" align="center">
          <el-table-column prop="scoreYearOther" align="center" label="最低分" min-width="70"></el-table-column>
          <el-table-column prop="accumulateYearOther" align="center" label="最低位次"></el-table-column>
          <el-table-column prop="admitYearOther" align="center" label="录取人数"></el-table-column>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getProvinceList } from "@/api/index.js";
import tableItem from "@/components/tableItem";
import tableHeader from "@/components/tableHeader";
import { mapState } from "vuex";
import { selectAnalog, saveAspiration } from "@/api/IntelligentReporting";
export default {
  components: {
    tableItem,
    tableHeader,
  },
  data() {
    return {
      dialogVisible: false,
      listQuery: {
        collegeCharacter: [],
        collegeProvince: null,
        ambitTypes: [],
      },
      // volunteerForm: [],
      // 院校省市
      provinceList: [],
      // 院校特性
      characteristicList: [
        // 1表示211，2表示985，3表示双一流
        {
          label: "211",
          value: "1",
        },
        {
          label: "985",
          value: "2",
        },
        {
          label: "双一流",
          value: "3",
        },
      ],
      // 院校类型
      typeList: [
        // 1综合，2理工，3文史，4师范，5教育，6财经，7艺术，8农林，9军事，10民族，11政法，12医药，13体育，14预科，20其他
        {
          label: "综合类",
          value: "1",
        },
        {
          label: "理工类",
          value: "2",
        },
        {
          label: "文史类",
          value: "3",
        },
        {
          label: "师范类",
          value: "4",
        },
        {
          label: "教育类",
          value: "5",
        },
        {
          label: "财经类",
          value: "6",
        },
        {
          label: "艺术类",
          value: "7",
        },
        {
          label: "农林类",
          value: "8",
        },
        {
          label: "军事类",
          value: "9",
        },
        {
          label: "民族类",
          value: "10",
        },
        {
          label: "政法类",
          value: "11",
        },
        {
          label: "医药类",
          value: "12",
        },
        {
          label: "体育类",
          value: "13",
        },
        {
          label: "预科类",
          value: "14",
        },
        {
          label: "其他",
          value: "20",
        },
      ],
      activeName: "1", //当前tab
      voPa: null,
      tableData: [], //可冲击
      tableData2: [], //较稳妥
      tableData3: [], //可保底
      volunteerList: [], //我的志愿
      titleName: "", //专业名
      dialogVisible1: false, //专业
      admissionsMajor: [], //招生专业
      year: "", //招生计划年
      yearLast: "",
      yearLast1: "",
      yearLast2: "",
      admissionYear: new Date().getFullYear()
    };
  },
  mounted() {
    this.voPa = this.$store.state.voPa || null;
    // this.activeName = this.voPa + "";
    // console.log(this.formObj, "333333");
    this.getProvinceList();
    this.getOptionalVolunteer();
  },
  computed: {
    ...mapState(["formObj", "studentInfo", "voNum"]),
  },
  methods: {
    // 查看专业弹框
    viewDiscipline(row) {
      if(this.voPa == 3){
        this.titleName = row.specialtyOptions;
      }else {
        this.titleName = row.collegeName;
      }
      
      this.admissionsMajor = row.specialtyList || [];
      this.dialogVisible1 = true;
    },

    // 院校省份改变
    changeCollegeProvince(val) {
      if (val) {
        this.formObj.collegeProvince = val;
      } else {
        this.formObj.collegeProvince = null;
      }
      this.getOptionalVolunteer();
    },

    // 院校特性改变
    changeCollegeCharacter() {
      this.getOptionalVolunteer();
    },

    // 院校类型
    changeAmbitTypes() {
      this.getOptionalVolunteer();
    },

    // 查询专业
    getOptionalVolunteer() {
      let searchData = {
        accumulate: this.formObj.accumulate,
        admitBatch: this.formObj.admitBatchName,
        ambitTypes: this.listQuery.ambitTypes + "",
        collegeCharacter: this.listQuery.collegeCharacter + "",
        collegeProvince: this.listQuery.collegeProvince,
        disciplineType: this.formObj.disciplineType,
        cityGrade: "",
        examProvince: {
          id: this.studentInfo.province,
          name: this.studentInfo.residenceProvince,
        },
        specialtyNames: this.formObj.specialtyNames,
        ignoreProvinces: this.formObj.examProvincesNot,
        provinces: this.formObj.examProvinces,
        totalScore: +this.formObj.totalScore,
      };
      // let searchData = {
      //   pattern: 2,
      //   totalScore: "657",
      //   disciplineTypeStr: "理科",
      //   disciplineType: 1,
      //   accumulate: 314,
      //   admitBatch: "本科一批",
      //   volunteerCount: 6,
      //   specialtyNames: "口腔,软件工程,口腔医学",
      //   examProvince: {
      //     id: "450000",
      //     name: "重庆市",
      //   },
      //   provinces: [
      //     {
      //       name: "北京",
      //       id: "110000",
      //       isChecked: true,
      //     },
      //     {
      //       name: "上海",
      //       id: "310000",
      //       isChecked: true,
      //     },
      //     {
      //       name: "广东",
      //       id: "440000",
      //       isChecked: true,
      //     },
      //     {
      //       name: "重庆",
      //       id: "500000",
      //       isChecked: true,
      //     },
      //     {
      //       name: "四川",
      //       id: "510000",
      //       isChecked: true,
      //     },
      //   ],
      //   ignoreProvinces: [
      //     {
      //       name: "西藏",
      //       id: "540000",
      //       isChecked: true,
      //     },
      //     {
      //       name: "青海",
      //       id: "630000",
      //       isChecked: true,
      //     },
      //     {
      //       name: "新疆",
      //       id: "650000",
      //       isChecked: true,
      //     },
      //   ],
      // };
      selectAnalog(searchData).then((res) => {
        if (res.code == 1) {
          // console.log(res, "eeeeeeeeeee");
          let resData = res.data || {};
          // listb 保底
          // listc 冲击
          // listw 稳妥
          let listc = resData.listc || [];
          if (listc.length > 0) {
            let firstItem = listc[0] || {};
            this.admissionYear = firstItem.admissionYear
            this.year = firstItem.year;
            this.yearLast = firstItem.yearLast - 1;
            this.yearLast1 = this.yearLast ? this.yearLast - 1 : "";
            this.yearLast2 = this.yearLast ? this.yearLast - 2 : "";
          }
          this.tableData = resData.listc || [];
          this.tableData2 = resData.listw || [];
          this.tableData3 = resData.listb || [];
          this.voPa = resData.voPa;
          this.$store.commit("setVoPa", resData.voPa);
        }
      });
    },

    // 加入志愿
    joinVolunteers(row) {
      let specialtyUuidArr = this.volunteerList.map(
        (item) => item.specialtyUuid
      );
      if (specialtyUuidArr.length > 0) {
        if (!specialtyUuidArr.includes(row.specialtyUuid)) {
          this.volunteerList.push({
            ...row,
            sort: "",
          });
        }
      } else {
        this.volunteerList.push({
          ...row,
          sort: "",
        });
      }
    },

    // 排序
    tableRank(row, index) {
      let reg = /^[1-9]\d*$/;
      if (!reg.test(row.sort)) {
        row.sort = index + 1 + "";
        this.$message.warning("请输入大于1的正整数");
        return;
      }
      if (row.sort > this.volunteerList.length) {
        row.sort = this.volunteerList.length;
      }

      let sort = index + 1;
      if (row.sort == sort) {
        return;
      }
      if (+row.sort > sort) {
        this.volunteerList.splice(row.sort, 0, row);
        this.volunteerList.splice(index, 1);
      } else {
        this.volunteerList.splice(row.sort - 1, 0, row);
        this.volunteerList.splice(index + 1, 1);
      }

      this.volunteerList.forEach((item, index) => {
        item.sort = index + 1 + "";
        this.$set(this.volunteerList, index, item);
      });
    },

    // 删除志愿
    deleteItem(index) {
      this.volunteerList.splice(index, 1);
      this.volunteerList.forEach((item, index) => {
        item.sort = index + 1 + "";
        this.$set(this.volunteerList, index, item);
      });
    },

    // 打开预览志愿
    previewVolunteers() {
      this.volunteerList.forEach((item, index) => {
        item.sort = index + 1 + "";
        this.$set(this.volunteerList, index, item);
      });
      console.log(this.volunteerList, "volunteerList");
      this.dialogVisible = true;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 保存
    savePlan() {
      if (this.volunteerList.length > this.voNum) {
        this.$message.warning("当前批次最多可填报" + this.voNum + "个志愿");
        return;
      }
      this.$store.commit("setVolunteerList", this.volunteerList);
      this.$router.push("/intelligentReporting/planDetail");
    },
    //   省份
    getProvinceList() {
      getProvinceList({}).then((res) => {
        if (res.code == 1) {
          this.provinceList = res.data || [];
        }
      });
    },
  },
  filters: {
    filterCollegeCharacter(val) {
      if (!val) {
        return "";
      }
      // 包含1表示211，2表示985，3表示双一流
      let obj = {
        1: "211",
        2: "985",
        3: "双一流",
      };
      let valArr = val.includes(",") ? val.split(",") : [val];
      let str = "";
      valArr.forEach((item) => {
        str = str += obj[item] + " ";
      });
      return str;
    },
    filterDisciplineAmbit(val) {
      if (!val) {
        return "";
      }
      let typeList = [
        // 1综合，2理工，3文史，4师范，5教育，6财经，7艺术，8农林，9军事，10民族，11政法，12医药，13体育，14预科，20其他
        {
          label: "综合类",
          value: "1",
        },
        {
          label: "理工类",
          value: "2",
        },
        {
          label: "文史类",
          value: "3",
        },
        {
          label: "师范类",
          value: "4",
        },
        {
          label: "教育类",
          value: "5",
        },
        {
          label: "财经类",
          value: "6",
        },
        {
          label: "艺术类",
          value: "7",
        },
        {
          label: "农林类",
          value: "8",
        },
        {
          label: "军事类",
          value: "9",
        },
        {
          label: "民族类",
          value: "10",
        },
        {
          label: "政法类",
          value: "11",
        },
        {
          label: "医药类",
          value: "12",
        },
        {
          label: "体育类",
          value: "13",
        },
        {
          label: "预科类",
          value: "14",
        },
        {
          label: "其他",
          value: "20",
        },
      ];
      let obj = {};
      typeList.forEach((item) => {
        obj[item.label] = item.value;
      });
      return obj[val];
    },
  },
};
</script>

<style lang="less" scoped>
.personalized-plan {
  .plan-title {
    text-align: center;
    font-size: 25px;
    color: #24b699;
    margin-bottom: 20px;
  }

  .tab-box {
    position: relative;

    .btn-box {
      position: absolute;
      right: 0;
      bottom: 20px;
      padding: 10px 8px;
      border-radius: 5px;
      background: #1ab394;
      color: #fff;
      cursor: pointer;
    }
  }

  .volunteer-content {
    .table-box {
      max-height: 500px;
      overflow-y: auto;
    }

    .delete-btn {
      width: 60px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 13px;
      color: #ff3333;
      border: 1px solid #ff3333;
      cursor: pointer;
    }

    .tip-mesage {
      color: #ff3333;
      margin: 20px 0;
    }

    .save-btn {
      margin: 0 auto;
      margin-top: 40px;
      width: 420px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #1ab394;
      color: #fff;
      cursor: pointer;
      border-radius: 50px;
    }
  }

  .view-btn {
    width: 75px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #1ab394;
    border: 1px solid #1ab394;
    border-radius: 15px;
    cursor: pointer;
    margin: 0 auto;
  }

  .red-color {
    color: #ff3333;
  }

  .yellow-color {
    color: #f59a23;
  }

  .green-color {
    color: #03bf16;
  }

  /deep/.el-table th.el-table__cell.more-header>.cell {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
  }

  /deep/.el-table .el-table__cell.more-header {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
  }

  /deep/.el-table .cell {
    padding: 0;
  }

  /deep/.el-table .el-table__cell:not(.more-header) {
    padding: 10px;
  }
}
</style>